import getUrl from '@/utils/getUrl';
import { getRecommendationEndpoint, getRecommendationMicroservice } from '@/utils/index';
import { getSeenArticlesAsStrings } from '@/utils/seenArticles';
import logger from 'lib/utils/logger';
import getConfig from 'modules/config';
import { brandConfig, defaultRequestOptions, optimizeValuesDefault, qsValuesDefault } from './config';
import { compileOptions, isCmpDisabled, waitForCmpClosed, waitForGoogleAnalytics } from './helpers';
import { DefaultRequestOptions } from './types';

async function fetchCall(resource: string, options: RequestInit & { timeout?: number }) {
  const response =
    typeof AbortController !== 'undefined' ? await fetchWithTimeout(resource, options) : await fetch(resource, options);
  return response;
}

/**
 * fetch() with timeout option.
 * @see https://dmitripavlutin.com/timeout-fetch-request/
 *
 * @param {string} resource
 * @param {object} options
 * @returns {object}
 */
async function fetchWithTimeout(resource: string, options?: RequestInit & { timeout?: number }) {
  const { timeout = 8000 } = options ?? {};

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
}

export function readyToFetch() {
  const readyPromise = new Promise(async (resolve) => {
    try {
      await waitForCmpClosed();
    } catch (e) {
      logger.catch('CMP was not closed within timeout.');
      resolve(false);
      return;
    }

    // Lets see if we are allowed to track.

    const isAllowedToTrack = !isCmpDisabled && window.OnetrustActiveGroups?.indexOf('C0004') >= 0;

    if (isAllowedToTrack) {
      waitForGoogleAnalytics()
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          logger.catch('Google Analytics never became available.', err);
          resolve(false);
        });
    } else {
      logger.info('Not waiting for Google Analytics (not allowed to track).');
      resolve(false);
    }
  });

  return readyPromise;
}

function getGoogleOptimizeSetup() {
  const optimizeSetup = { ...optimizeValuesDefault };

  // Read values from window set by Google Optimize.
  if (Object.prototype.hasOwnProperty.call(window, 'ra_showrecommendations')) {
    optimizeSetup.showRecommendations = !!+window.ra_showrecommendations;
    logger.info('"showRecommendations" value set by Google Optimize, value: ', optimizeSetup.showRecommendations);
  }

  // @todo: legacy name, remove this once test on Elle is finished.
  if (Object.prototype.hasOwnProperty.call(window, 'showRecommendations')) {
    optimizeSetup.showRecommendations = !!+window.showRecommendations;
    logger.info(
      '"showRecommendations" value set by Google Optimize (legacy), value: ',
      optimizeSetup.showRecommendations,
    );
  }

  return optimizeSetup;
}

function getQsSetup() {
  const qsSetup = { ...qsValuesDefault };

  // Read values from query string.
  try {
    const searchParams = new URLSearchParams(window.location.search);

    if (window.location.href.indexOf('ra_gaid=') >= 0) {
      qsSetup.gaId = searchParams.get('ra_gaid');
      logger.info('"gaId" value overridden by query string parameter, value:', qsSetup.gaId);
    }

    if (window.location.href.indexOf('ra_showrecommendations=') >= 0) {
      qsSetup.showRecommendations = !!+(<string>searchParams.get('ra_showrecommendations'));
      logger.info(
        '"showRecommendations" value overridden by query string parameter, value: ',
        qsSetup.showRecommendations,
      );
    }
  } catch (err) {
    logger.catch('URLSearchParams is not available, query string overrides ignored.');
  }
  return qsSetup;
}

export function generalSetup() {
  const optimizeSetup = getGoogleOptimizeSetup();
  const qsSetup = getQsSetup();
  const brand = defaultRequestOptions.brand;
  // Should we show recommendations? (only applies if `defaultHidden: true`).
  const showRecommendations = qsSetup.showRecommendations ?? optimizeSetup.showRecommendations ?? false;

  const options = {
    /* Uncomment these lines for dev or debugging */
    // gaId: '487506200.1630585561',
    // responseTimeout: 5000,
    gaId: '',
    brand,
    showRecommendations,
  };

  // Did we override gaId by providing it in the query string?
  if (qsSetup.gaId !== null) {
    options.gaId = qsSetup.gaId;
  }

  return options;
}
const getContextualAndPersonalisedRecommendations = (requestOptions: DefaultRequestOptions): Promise<Response> => {
  const baseUrl = getUrl('/articleRecommendations', getRecommendationMicroservice());
  const body = {
    brand: requestOptions.brand,
    cookieId: requestOptions.gaId,
    articleIds: getSeenArticlesAsStrings(),
  };

  return fetchCall((baseUrl as URL).href, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: requestOptions.responseTimeout,
  });
};

const getContextualRecommendations = (requestOptions: DefaultRequestOptions): Promise<Response> => {
  const baseUrl = `${getRecommendationEndpoint()}?`;
  const params = [];

  if (requestOptions.articleId !== null) {
    params.push(`article_id=${requestOptions.articleId}`);
  }

  if (requestOptions.brand !== null) {
    params.push(`brand=${requestOptions.brand}`);
  }

  if (requestOptions.gaId !== null) {
    params.push(`cookie_id=${requestOptions.gaId}`);
  }

  // Make the request.
  return fetchCall(baseUrl + params.join('&'), {
    timeout: requestOptions.responseTimeout,
  });
};

export async function getRecommendedArticles(articleId: string) {
  const options = generalSetup();
  const requestOptions = compileOptions({ ...options, articleId });

  logger.info('Requesting recommendations.', requestOptions);

  if (brandConfig.defaultHidden && requestOptions.showRecommendations === false) {
    logger.info('Recommendations are hidden by default.');
    return [];
  }

  const generateRandomNumber = () => Math.floor(Math.random() * 100) + 1;
  const userPercent = getConfig('recommendedArticles.personalisedRecommendations.visibleUserPercent') || 0;
  const isPersonalisedRecBucket = generateRandomNumber() <= userPercent;

  const response = isPersonalisedRecBucket
    ? await getContextualAndPersonalisedRecommendations(requestOptions)
    : await getContextualRecommendations(requestOptions);

  if (response.ok) {
    return await response.json();
  } else {
    throw Error('HTTP-error ' + response.status);
  }
}
