import { StandaloneBanner } from 'base/components/Banner';
import BackgroundImage from 'base/public/image/banner-games-image.svg';
import getUrl from '@/utils/getUrl';
import withTrackingAttributes from '@/utils/withTrackingAttributes';
import Tracking from 'base/components/TrackingComponent';
import { Button } from '@/components/Button';
import getConfig from 'modules/config';

const { frontPagePathname } = getConfig('keesingGames');
const ctsUrl = getUrl(frontPagePathname);
const ctsAttrs = withTrackingAttributes({
  object: 'promotion_banner',
  creative: 'promotion_banner',
  objectName: 'teaser',
  name: 'teaser',
  objectUrl: ctsUrl ? ctsUrl.toString() : frontPagePathname,
  id: ctsUrl ? ctsUrl.toString() : frontPagePathname,
  objectExtraInfo: [{ campaign: 'keesing_games' }],
  label: [{ campaign: 'keesing_games' }],
});

export const SvenskdamStandaloneBanner: typeof StandaloneBanner = (props) => {
  const backgroundImageMemo = <BackgroundImage />;
  const contentMemo = (
    <>
      <h1 className="text-headline-lg">Nyhet!</h1>
      <p className="text-input-md">Korsord, sudoku och spel</p>
      <Button
        link={{ href: frontPagePathname }}
        options={{ size: 'large', className: 'self-start' }}
        content={'Klicka här för att spela'}
      />
    </>
  );

  return (
    <Tracking options={{ wrapChildren: true, ctsAttrs }}>
      <StandaloneBanner content={contentMemo} backgroundImage={backgroundImageMemo} {...props} />
    </Tracking>
  );
};
