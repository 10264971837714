import isServer from '@/utils/isServer';
import logger from '@/utils/logger';

const sessionStorageKey = 'seenArticleIds';

export const saveSeenArticle = (articleId: number) => {
  if (isServer()) return;

  const value = sessionStorage.getItem(sessionStorageKey);

  try {
    const articlesIds: number[] = value ? JSON.parse(value) : [];
    const uniqueArticleIds = Array.from(new Set([...articlesIds, articleId]));

    sessionStorage.setItem(sessionStorageKey, JSON.stringify(uniqueArticleIds));
  } catch (error) {
    logger.catch(error);
  }
};

export const getSeenArticleIds = (): number[] => {
  if (isServer()) return [];

  const value = sessionStorage.getItem(sessionStorageKey);

  try {
    return value ? JSON.parse(value) : [];
  } catch (error) {
    logger.catch(error);
    return [];
  }
};

export const getSeenArticlesAsStrings = (): string[] => {
  return getSeenArticleIds().map((id) => id.toString());
};
