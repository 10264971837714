import { ShowsSlider } from '@/components/ShowsSlider';
import { StandaloneArticleFooter } from 'base/components/ArticleFooter';
import type { Show } from 'base/components/ShowsSlider';
import { getAllShowsPageData } from 'lib/data/video';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';

const fetchShows = async () => {
  const data = await getAllShowsPageData();

  const shows: Show[] =
    data?.shows?.playlist?.map<Show>((playlist) => ({
      description: playlist.description,
      src: playlist.image,
      playlistId: playlist.mediaId,
      title: playlist.title,
      url: playlist.url,
    })) ?? [];
  return shows;
};

export const SvenskdamStandaloneArticleFooter: typeof StandaloneArticleFooter = (props) => {
  const [shows, setShows] = useState<Show[]>([]);

  useEffectOnce(() => {
    fetchShows().then((shows) => setShows(shows));
  });

  return (
    <>
      <StandaloneArticleFooter {...props} />
      <ShowsSlider shows={shows} />
    </>
  );
};
