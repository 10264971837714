import { tw } from '@/utils/tw';
import BaseBreakingNewsTheme from 'base/components/BreakingNews/theme';

const Icon = tw.theme({
  extend: BaseBreakingNewsTheme.Icon,
});

const BreakingNews = tw.theme({
  extend: BaseBreakingNewsTheme,
  variants: {
    variant: {
      secondary: {
        headline: `sm:text-headline-3xl mt-1 text-headline-2xs sm:mt-3`,
        label: `text-headline-3xs sm:text-headline-2xs`,
      },
    },
  },
  defaultVariants: {
    variant: 'secondary',
  },
});

export default Object.assign(BreakingNews, { Icon });
