import { JwVideoTeaser } from '@/components/JwVideo/Teaser';
import { componentTheme } from '@/styles/JwVideoTeaser';
import { LabradorComponent } from '@/types/component';

export const LabradorJwVideoTeaser: LabradorComponent = ({ type, meta, data }) => {
  const { videoID, thumbnail, isFrontPage, teaserLink, title, hintText, stillImage, playlist, prominentTag } = data;
  const brand = playlist?.brand ?? playlist?.[0]?.brand;

  const componentProps = {
    mediaId: videoID,
    teaserLink,
    thumbnail,
    title,
    hintText,
    isStillImage: stillImage,
    tag: prominentTag,
    'data-index': meta.teaserIndex,
    ...(isFrontPage
      ? {
          options: {
            theme: componentTheme('front_page'),
            variant: 'vertical',
            $content: {
              colors: 'default',
              $tag: {
                theme: componentTheme('tag'),
              },
            },
          },
        }
      : {
          headline: `VIDEOS FRÅN ${brand?.name ?? brand}`,
          options: {
            variant: 'horizontal',
            $content: {
              colors: 'secondary',
              $tag: {
                theme: componentTheme('tag'),
              },
            },
          },
        }),
  };

  return <JwVideoTeaser {...componentProps} />;
};
